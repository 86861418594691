import { City } from "@shared/lib/interfaces/city.interface";

export const POPULAR_CITIES: City[] = [
    { name: "Москва", alias: "moskva" },
    { name: "Санкт-Петербург", alias: "sankt-peterburg" },
    { name: "Владивосток", alias: "vladivostok" },
    { name: "Краснодар", alias: "krasnodar" },
    { name: "Екатеринбург", alias: "ekaterinburg" },
    { name: "Новосибирск", alias: "novosibirsk" },
    { name: "Красноярск", alias: "krasnoyarsk" },
    { name: "Челябинск", alias: "chelyabinsk" },
    { name: "Казань", alias: "kazan" },
    { name: "Воронеж", alias: "voronezh" },
    { name: "Ростов-на-Дону", alias: "rostov-na-donu" },
    { name: "Омск", alias: "omsk" },
    { name: "Тюмень", alias: "tyumen" },
    { name: "Нижний Новгород", alias: "nizhnii-novgorod" },
    { name: "Уфа", alias: "ufa" },
    { name: "Самара", alias: "samara" },
    { name: "Иркутск", alias: "irkutsk" },
    { name: "Волгоград", alias: "volgograd" },
    { name: "Пермь", alias: "perm" },
    { name: "Ярославль", alias: "yaroslavl" }
];

export const DEBOUNCE_INPUT_TEXT = 300;
export const SEARCH_INPUT_TEXT = "Регион, город, населенный пункт";
export const DETECT_BUTTON_TEXT = "Определить автоматически";
export const DISTANCE_LABEL_TEXT = "Расширение радиуса поиска, км";
export const SAVE_BUTTON_TEXT = "Сохранить";