import { WithContext, Thing } from "schema-dts";
import Head from "next/head";

interface SchemaJsonLdProps {
    schemas: WithContext<Thing>[];
}

export const SchemaJsonLd = ({ schemas }: SchemaJsonLdProps) => {
    return (
        <Head>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(schemas)
                }}
            />
        </Head>
    );
};