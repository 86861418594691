import React, { useRef } from "react";
import { DateTime } from "luxon";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@app/store/store";
import { saveCity } from "@app/store/filtersSlice";
import { useOutsideClick } from "@shared/lib/hooks/useOutsideClick";
import { LocationConfirmProps } from "./types";
import { CHANGE_BUTTON_TEXT, CONFIRM_BUTTON_TEXT, CONFIRM_QUESTION_TEXT } from "./constants";
import classes from "./LocationConfirm.module.scss";
import { City } from "@shared/lib/interfaces/city.interface";

const LocationConfirm = ({ onClose, onChange }: LocationConfirmProps) => {
    const locationConfirmRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch<AppDispatch>();

    useOutsideClick(locationConfirmRef, () => {
        onClose();
    });

    const handleConfirmCity = () => {
        const city = localStorage.getItem("city");
        if (city) {
            const parsedCity: City = JSON.parse(city);
            dispatch(saveCity(parsedCity));
            localStorage.setItem("cityTimestamp", DateTime.now().toISO());
        }
        onClose();
    };

    return (
        <div className={classes.LocationConfirm} ref={locationConfirmRef}>
            <span className={classes.LocationConfirm__question}>{CONFIRM_QUESTION_TEXT}</span>
            <button
                className={classNames(
                    classes.LocationConfirm__button,
                    classes["LocationConfirm__button-confirm"]
                )}
                aria-label={CONFIRM_BUTTON_TEXT}
                onClick={handleConfirmCity}
            >
                {CONFIRM_BUTTON_TEXT}
            </button>
            <button
                className={classNames(
                    classes.LocationConfirm__button,
                    classes["LocationConfirm__button-change"]
                )}
                aria-label={CHANGE_BUTTON_TEXT}
                onClick={onChange}
            >
                {CHANGE_BUTTON_TEXT}
            </button>
        </div>
    );
};

export default LocationConfirm;